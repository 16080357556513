<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="名称" prop="name">
                <el-input
                        v-model="queryParams.name"
                        clearable
                        size="mini"
                />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                        type="primary"
                        plain
                        v-permission="'clue:pond:add'"
                        size="mini"
                        @click="handleAdd"
                >添加池子
                </el-button>
            </el-col>
        </el-row>

        <el-table max-height="660" v-loading="loading" :data="dataList" >
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="主键" align="center" prop="code"/>
            <el-table-column label="名称" align="center" prop="name"/>
            <el-table-column label="排序" align="center" prop="sort"/>
<!--            <el-table-column label="状态" align="center" prop="status"/>-->
            <el-table-column label="操作" align="center" fixed="right" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button
                            v-permission="'clue:pond:update'"
                            size="mini"
                            type="text"
                            @click="handleUpdate(scope.row)"
                    >修改
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.current"
                :limit.sync="queryParams.size"
                @pagination="getList"
        />

        <!-- 添加或修改参数配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="80%" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入名称"/>
                </el-form-item>
                <el-form-item label="排序值" prop="sort">
                    <template #label>
                        <el-tooltip class="item" effect="dark" content="数值越大越靠前,0-9999" placement="top">
                        <span>排序值<i class="el-icon-question"></i></span>
                    </el-tooltip>
                    </template>
                    <el-input v-model="form.sort" placeholder="请输入排序值"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as cluePondApi from "@/api/clue/cluePond";
import CluePage from "./component/CluePage.vue";
import * as possApi from "@/api/crm/possess";

export default {
    name: "CluePond",
    components: {CluePage},
    data() {
        return {
            queryParams: {
                current:1,
                size:10
            },
            form: {
                pondIds: "",
            },
            title: "",
            total: 0,
            open: false,
            rules: {},
            loading: false,
            dataList: [],
            adviserList: [],
            single: false,
            pondList: [],
            expandall: false,
            defaultProps: {
                children: 'children',
                label: 'title'
            },
        };
    },
    created() {
        this.getList();
        this.getDeptTree();
    },
    methods: {
        getDeptTree(id) {
            possApi.findDeptTreeByPoss(id).then(res => {
                this.deptTree = res.data;
            })
        },
        /** 查询参数配置列表 */
        getList() {
            // this.loading = true;
            cluePondApi.pageCluePond(this.queryParams).then(response => {
                this.dataList = response.data.records;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.queryParams = {
                current: 1,
                size: 10,
                startTime: null,
                endTime: null,
                action: null,
                module: null,
                accountId: null
            }
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.current = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.configId)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.form = {
                configId: null,
                configName: null,
                configKey: null,
                configValue: null,
                configType: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
                remark: null
            };
            this.open = true;
            this.title = "添加配置";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.form = {
                configId: null,
                configName: null,
                configKey: null,
                configValue: null,
                configType: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
                remark: null
            };
            let id = row.id
            cluePondApi.infoCluePond(id).then(response => {
                this.form = response.data;
                this.open = true;
                this.title = "修改配置";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.form.id != null) {
                        cluePondApi.updateCluePond(this.form).then(response => {
                            this.msgSuccess("修改成功");
                            this.open = false;
                            this.getList();
                        });
                    } else {
                        cluePondApi.addCluePond(this.form).then(response => {
                            this.msgSuccess("新增成功");
                            this.open = false;
                            this.getList();
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.ids || this.ids;
            this.$confirm('是否确认删除线索池为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                return cluePondApi.deleteCluePond(ids)
            }).then(() => {
                this.getList();
                this.msgSuccess("删除成功");
            })
        }
    }
}
</script>

<style scoped>

</style>